import React from "react";
import styled from "styled-components";
import Layout from "../components/containers/layout";

import SEO from "../components/globals/seo";

const headerTempStyle = {
  margin: `40px`,
};
const InfoTempStyle = styled.div`
  margin: 40px;
  li {
    margin: 5px 0;
  }
`;

const coppaPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="COPPA"
          description="Childrens Online Privacy Policy"
          path="/coppa"
        />

        <h1 style={headerTempStyle}>TopTekkers COPP (Childrens Online Privacy Policy)</h1>
        <InfoTempStyle>
          <p>TopTekkers Children&rsquo;s Online Privacy Policy</p>
          <p>TopTekkers(a trading name of The Coaching Manual Limited (&ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;)) is committed to protecting the privacy of your information. The following privacy policy sets forth our policies and practices regarding the collection, use, and disclosure of information we collect from children under the age of 13 through our TopTekkers App and Website (the &ldquo;App&rdquo;). This privacy policy affirms our commitment to complying with the Children&rsquo; Online Privacy Protection Act of 1998 (COPPA).</p>
          <p>THE INFORMATION WE COLLECT FROM CHILDREN</p>
          <p>We may collect certain personal information from children under the age of 13 who perform activities using the App, such as technique training activities or challenges. Specifically, we may collect: (1) the child&rsquo;s first and last name; (2) the child&rsquo;s birth year; (3) the child&rsquo;s challenge scores; (4) the child&rsquo;s coach connection and (5) the child&rsquo;s usage of the App(collectively, &ldquo;Children&rsquo;s Information&rdquo;).</p>
          <p>When information on the App (such as challenge scores) is updated, any Children&rsquo;s Information collected through the App will be transferred to our servers. The Children&rsquo;s Information will then be associated with other personal information about the child that TopTekkers maintains in the online user account created by the child&rsquo;s parent, legal guardian, or other authorized representative of the child, such as the coach of a sports team to which the child belongs. We will retain Children&rsquo;s Information only for as long as is reasonably necessary to fulfill the purpose(s) for which the information was collected.</p>
          <p>HOW WE USE AND DISCLOSE CHILDREN&rsquo;S INFORMATION</p>
          <p>PROVISION OF SERVICES.We may use Children&rsquo;s Information to support the delivery of our services. For example, we may generate reports using Children&rsquo;s Information and make them available to the child&rsquo;s parent, legal guardian, or other authorized representative through a TopTekkers online user account. We may also make Children&rsquo;s Information accessible to third-party service providers that assist us with managing our daily business operations, providing e-commerce services, and marketing our services. When appropriate, we will enter into written agreements with third-party service providers requiring them to protect the confidentiality of the information we share with them.</p>
          <p>IMPROVING OUR SERVICES.We may also use Children&rsquo;s Information to enhance our understanding of our users&rsquo; preferences and improve our services accordingly.</p>
          <p>DATA ANALYSIS AND RESEARCH.We may share information collected from children who use our Services with third parties, including universities and health organizations, for data analysis and research purposes. Any information we share about users with such third parties will be in a format that does not personally identify the users.</p>
          <p>LAW ENFORCEMENT, SAFETY, AND LEGAL PROCESSES.We may share Children&rsquo;s Information with law enforcement or other government officials if it relates to a criminal investigation or alleged criminal activity. We may also disclose Children&rsquo;s Information if required or permitted to do so by law; for fraud protection and credit risk reduction purposes, or in the good-faith belief that such action is necessary to protect and defend the rights or property of TopTekkers or the users of our services; and to act under urgent circumstances to protect the safety of TopTekkers or its employees or a member of the public. We may also disclose Children&rsquo;s Information to comply with a judicial proceeding, court order, subpoena, or legal process.</p>
          <p>SALE OR ACQUISITION OF ASSETS.If we become involved in a transaction involving the sale of our assets, such as a merger or acquisition, or if our website or mobile application is transferred to another company, we may disclose and/or transfer Children&rsquo;s Information as part of the transaction. If we are not the surviving entity in that transaction, the surviving entity may use Children&rsquo;s Information pursuant to its own privacy policies and practices, which may differ from this policy.</p>
          <p>CONSENT FOR THE COLLECTION, USE, AND DISCLOSURE OF CHILDREN&rsquo;S INFORMATION</p>
          <p>'We do not collect, use, or disclose Children&rsquo;s Information without first obtaining verifiable consent from the child&rsquo;s parent or legal guardian. To provide consent, a parent or legal guardian must agree to our terms and conditions which can be found here (Insert Link). When possible, TopTekkers may obtain verifiable consent from parents&rsquo; and legal guardians when they make transactions using their credit or debit cards.'&nbsp;</p>
          <p>WITHDRAWING CONSENT</p>
          <p>A parent or legal guardian may request TopTekkers to stop collecting, using, or disclosing Children&rsquo;s Information about his or her child by notifying us at the contact information listed below.&nbsp;</p>
          <p>REVIEWING, CHANGING, OR DELETING CHILDREN&rsquo;S INFORMATION</p>
          <p>A parent or legal guardian may access, change, or delete Children&rsquo;s Information about his or her child by logging onto the TopTekkers online user account or profile linked to the child&rsquo;s information. A parent or legal guardian may also reach us at the contact information listed below for assistance with accessing, changing, or deleting Children&rsquo;s Information. Modifying, updating, or deleting any information will not result in the modification, updating, or deletion of any such information that may reside in back-up or disaster-recovery storage.</p>
          <p>UPDATES TO THE CHILDREN&rsquo;S ONLINE PRIVACY POLICY.</p>
          <p>We may periodically revise this policy in our sole and absolute discretion to reflect changes in the law or our business practices. If we revise the policy, we will post the updated policy on our website. Any material changes to the ways in which we collect, use, or disclose Children&rsquo;s Information will require us to obtain new consent from parents or legal guardians.</p>
          <p>REQUESTS, COMMENTS, AND QUESTIONS.</p>
          <p>'If you have a request, comment, or question related to the Children&rsquo;s Online Privacy Policy, please reach us at the contact information listed below:'&nbsp;</p>
          <p>TopTekkers [A trading name of The Coaching Manual Limited]</p>
          <p>Altspace</p>
          <p>F1</p>
          <p>Kennedy House</p>
          <p>31 Stamford St</p>
          <p>Altrincham</p>
          <p>WA14 1ES</p>
          <p><a href="mailto:help@toptekkers.com">help@toptekkers.com</a></p>
          <p>Terms</p>
          <p>General Privacy Policy</p>
        </InfoTempStyle>
      </Layout>
    </>
  );
};

export default coppaPage;
